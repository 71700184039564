<template>
    <div class="py-4 equipment_box">
        <!-- <div
            class="text-left text-gray-500 path"
        >首页-售后服务-{{ tabIndex == 0 ? '设备绑定' : tabIndex == 1 ? '设备解绑' : "设备维修" }}</div>-->
        <div class="text-left text-gray-500 path">首页-售后服务-{{ tabIndex == 0 ? '设备绑定' : "设备维修" }}</div>
        <div class="flex items-center my-3">
            <div v-for="(item, index) in tabs" :key="index" class="mr-8 font-bold cursor-pointer"
                :class="tabIndex == index ? 'text-primary' : ''" @click="changeTab(index)">{{ item }}</div>
        </div>
        <div v-if="tabIndex == 0">
            <div class="flex mt-8 duration-500 sm:justify-center equipment">
                <el-form ref="form" label-width="90px" label-position="center">
                    <el-form-item label="电脑型号" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-select v-model="modlesId" placeholder="请选择" style="width:100%">
                                <el-option v-for="item in modles" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="phone_select_show">
                            <div class="flex form_input custom_input" @click="openPopup('mobilepopup3')" style="width:100%">
                                <span :class="modlesId == '' ? 'text-gray-300' : ''">
                                    {{
                                        modlesId == "" ? "请选择" : transformSelect(modles, modlesId)
                                    }}
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="序列号" class="flex justify-start">
                        <el-input v-model="serialNum" placeholder="请填写设备序列号" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="订单号" class="flex justify-start">
                        <el-input
                            v-model="orderNum"
                            placeholder="请填写购机订单号"
                            class="duration-500 form_input"
                        ></el-input>
                    </el-form-item>-->
                    <el-form-item label="购买渠道" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-select v-model="buysId" placeholder="请选择" style="width:100%">
                                <el-option v-for="item in buys" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="phone_select_show">
                            <div class="flex form_input custom_input" @click="openPopup('mobilepopup2')" style="width:100%">
                                <span :class="buysId == '' ? 'text-gray-300' : ''">
                                    {{
                                        buysId == "" ? "请选择" : transformSelect(buys, buysId)
                                    }}
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" @click="checkRules('bind')" class="duration-500 btn">确定</el-button>
                        <!-- <el-button
                            type="primary"
                            @click="checkRules('bind')"
                            class="duration-500 btn"
                            v-if="tabIndex == 0"
                        >确定</el-button>-->
                        <!-- <el-button v-else class="duration-500 btn" @click="checkRules('unbind')">解绑</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="mt-8 mb-4 font-bold text-left">设备信息</div>
            <div class="table w-full">
                <div class="flex items-center w-full duration-500 table_title">
                    <div v-for="(item, index) in tableTitle" :key="index" class="flex items-center h-full duration-500"
                        style="width:33.33%"
                        :class="index == 0 ? 'justify-start' : index == 3 ? 'justify-end' : 'justify-center'">{{ item }}
                    </div>
                </div>
                <ul class="w-full" v-if="getEquipment.length > 0">
                    <li class="flex items-center w-full duration-500" v-for="(item, index) in getEquipment" :key="index">
                        <div class="flex items-center justify-start duration-500 li_item">{{ item.commodityName }}</div>
                        <div class="flex items-center justify-center duration-500 li_item">{{ item.warrantyDate == null ?
                            '--' : item.warrantyDate }}</div>
                        <div class="flex items-center justify-center duration-500 li_item">{{ item.integral == null ? '--' :
                            item.integral }}</div>
                        <div class="flex items-center justify-end duration-500 cursor-pointer li_item text-primary"
                            @click="unbindOperation(item)">解绑</div>
                    </li>
                </ul>
                <div class="flex items-center justify-center py-4 text-gray-500 null_box" v-else>未绑定相关设备</div>
            </div>
        </div>
        <div v-if="tabIndex == 1">
            <div class="flex mt-8 duration-500 sm:justify-center equipment">
                <el-form ref="form" label-width="90px" label-position="left">
                    <el-form-item label="电脑型号" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-select v-model="modlesId2" placeholder="请选择" style="width:100%">
                                <el-option v-for="item in modles" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div class="phone_select_show">
                            <div class="flex form_input custom_input" @click="openPopup('mobilepopup4')" style="width:100%">
                                <span :class="modlesId2 == '' ? 'text-gray-300' : ''">
                                    {{
                                        modlesId2 == "" ? "请选择" : transformSelect(modles, modlesId2)
                                    }}
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="序列号" class="flex justify-start">
                        <el-input placeholder="请填写您的序列号" v-model="code" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <el-form-item label="保修信息" class="flex justify-start">
                        <el-input placeholder="请填写您的保单信息" v-model="policy" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <el-form-item label="维修时间" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-date-picker type="date" placeholder="请选择您维修的时间" v-model="maintainTime"
                                class="duration-500 form_input pc_show" :picker-options="pickerOptions0"></el-date-picker>
                        </div>
                        <div class="phone_select_show">
                            <div class="flex form_input custom_input" @click="showDatePicker = true">
                                <span :class="maintainTime == '' ? 'text-gray-300' : ''">{{ maintainTime == '' ? '请选择您维修的时间'
                                    : maintainTime }}</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="地址" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"
                                class="form_input"></el-cascader>
                        </div>
                        <div class="phone_select_show">
                            <div class="flex form_input custom_input" @click="drawer = true">
                                <span :class="selectAddress ? '' : 'text-gray-300'">{{ selectAddress ? selectAddress :
                                    '请选择地址' }}</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="详细地址" class="flex justify-start">
                        <el-input placeholder="请填写详细地址" v-model="address" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人姓名" class="flex justify-start">
                        <el-input placeholder="请填写您的姓名" v-model="contactName" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" class="flex justify-start">
                        <el-input placeholder="请填写您的手机号码" v-model="phone" class="duration-500 form_input"></el-input>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" class="duration-500 btn" @click="maintenanceCheckRules()">确定</el-button>
                        <!-- <el-button style="width:200px">解绑</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="mt-8 mb-4 font-bold text-left">维修记录</div>
            <div class="table w-full">
                <div class="flex items-center w-full duration-500 table_title">
                    <div v-for="(item, index) in maintenance" :key="index" class="flex items-center h-full duration-500"
                        style="width:25%" :style="{ width: index == 0 ? '20%' : '26.6666%' }"
                        :class="index == 0 ? 'justify-start' : index == 3 ? 'justify-end' : 'justify-center'">{{ item }}
                    </div>
                </div>
                <ul class="w-full" v-if="maintainList.length > 0">
                    <li class="flex items-center w-full duration-500" v-for="item in maintainList" :key="item.maintainId">
                        <div class="flex items-center justify-start duration-500 ma_li_item" style="width: 20%;"
                            @click="goDetail(item)">火影T5</div>
                        <div class="flex items-center justify-center duration-500 ma_li_item" style="width: 26.66%;">{{
                            item.date }}</div>
                        <div class="flex items-center justify-center duration-500 ma_li_item" style="width: 26.66%;">{{
                            item.status == 1 ? '申请中' : item.status == 2 ? '正在维修' : '维修完成' }}</div>
                        <div class="flex items-center justify-end duration-500 ma_li_item" style="width: 26.66%;">
                            {{ item.logisticsStatus }}
                            <span class="cursor-pointer text-primary" @click="goLogistics(item)"
                                v-if="item.logisticsStatus != '无'">【详情】</span>
                        </div>
                    </li>
                </ul>
                <div class="flex items-center justify-center py-4 text-gray-500 null_box" v-else>暂无维修记录</div>
            </div>
            <!-- <el-button @click="showDatePicker = true">打开</el-button> -->
            <custom-date-picker language="zh" :show-picker-model="showDatePicker" :start-date="new Date('1968-10-20')"
                :end-date="new Date()" @cancel="handleCancel" @confirm="handleConfirm"></custom-date-picker>
            <!-- :disable-date="disableDate" -->
        </div>
        <el-drawer title :visible.sync="drawer" :direction="direction" :before-close="handleClose" :show-close="false"
            :withHeader="false" size="50%">
            <div style="height: 50vh;width: 100vw;">
                <v-distpicker type="mobile" @selected="selected"></v-distpicker>
            </div>
        </el-drawer>

        <MobilePopup ref="mobilepopup3" :list="modles" @select="selectFunc3"></MobilePopup>
        <MobilePopup ref="mobilepopup4" :list="modles" @select="selectFunc4"></MobilePopup>
        <MobilePopup ref="mobilepopup2" :list="buys" @select="selectFunc2"></MobilePopup>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { regionData } from 'element-china-area-data'
export default {
    data() {
        return {
            // tabs: ['设备绑定', '设备解绑', '设备维修'],
            tabs: ['设备绑定'],
            tableTitle: ['设备名称', '设备保修时间', '积累积分', '操作'],
            maintenance: ['设备名称', '设备维修时间', '设备维修状态', '设备物流状态'],
            tabIndex: 0,
            orderNum: "", // 订单号
            serialNum: "",// 序列号
            isMark: true,
            showDatePicker: false,
            selectedDate: '',
            options: regionData,
            selectedOptions: [],
            drawer: false,
            direction: 'btt',
            //省市区
            selectAddress: "",
            addressCode: "",
            code: "",// 序列号
            policy: "",// 保修单信息
            maintainTime: "",//维修时间
            cityId: "",//地址
            address: "",// 详细地址
            contactName: "",// 联系人姓名
            phone: "",// 联系人手机号

            // 设置elementui日期选择器只能选择今日之前的日期
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                }
            },
            value: "",
            maintainList: [],

            modles: [],// 型号
            modlesId: "",// 设备绑定选择的型号
            modlesId2: "", // 设备维修选择的型号
            buys: [],// 渠道
            buysName: "",
            buysId: ""
        };
    },
    created() {
        this.$store.dispatch("aquireEquipment", this.$EventBus)
        if (this.$route.query.tab) {
            this.tabIndex = this.$route.query.tab
        }
        this.findRepairList()
        this.getDriveCategory()
        this.getDropDownBoxData()
        // console.log(this.$route.query)
    },
    computed: {
        ...mapGetters(['getEquipment'])
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 转换（移动端需要根据value值显示对应的结果）
        transformSelect(list, value) {
            let result = list.filter(item => {
                return value == item.value
            })
            return result[0].label
        },

        // 打开下拉框 
        openPopup(type) {
            this.$refs[type].open();
        },
        selectFunc2(item) {
            this.buysId = item.value
        },
        selectFunc3(item) {
            this.modlesId = item.value
        },
        selectFunc4(item) {
            this.modlesId2 = item.value
        },
        // 获取保修单下拉框数据
        getDropDownBoxData() {
            this.$request({
                method: "get",
                url: this.$requestPath.getDropDownBoxData,
                data: {}
            }).then(res => {
                console.log("获取保修单下拉数据", res)
                let { buys, modles } = res.data
                this.buys = []
                // this.colours = []
                this.modles = []
                if (buys.length > 0) {
                    buys[0].driveCategoryDtos.forEach(item => {
                        item.value = item.driveCategoryId
                        item.label = item.categoryName
                    })
                    this.buys.push(...buys[0].driveCategoryDtos)
                }

                // if (colours.length > 0) {
                //     colours[0].driveCategoryDtos.forEach(item => {
                //         item.value = item.driveCategoryId
                //         item.label = item.categoryName
                //     })
                //     this.colours.push(...colours[0].driveCategoryDtos)
                // }
                if (modles.length > 0) {
                    modles[0].driveCategoryDtos.forEach(item => {
                        item.driveCategoryDtos.forEach(children => {
                            children.value = children.driveCategoryId
                            children.label = children.categoryName
                            this.modles.push(children)
                        })
                    })
                }
            }).catch(error => {
                console.log("获取保修单下拉数据", error)
            })
        },
        // 获取驱动查询条件
        getDriveCategory() {
            this.$request({
                method: "get",
                url: this.$requestPath.getDriveCategory,
                data: {}
            }).then(res => {
                console.log("获取驱动查询条件", res)
                let conditions = []
                res.data.forEach(item => {
                    item.categoryName == "条件查询" ? conditions.push(...item.driveCategoryDtos) : ""
                })
                let model = []
                conditions.forEach(item => {
                    if (item.categoryName == "品牌型号") {
                        model.push(...item.driveCategoryDtos)
                    }
                })
                model.forEach(item => {
                    item.value = item.driveCategoryId
                    item.label = item.categoryName
                })
                this.model = []
                this.model.push(...model)
                // console.log("model", this.model)

            }).catch(error => {
                console.log("获取驱动查询条件", error)
            })
        },

        goLogistics(item) {
            this.$router.push({
                path: '/index/sales/logistics', query: {
                    maintainId: item.maintainId,
                    kdType: item.kdType,
                }
            })
        },
        goDetail() {
            this.$router.push({ path: '/index/sales/info' })
        },
        handleCancel() {
            this.showDatePicker = false;
        },
        handleConfirm(item) {
            console.log(item)
            this.selectedDate = item;
            this.showDatePicker = false;
        },
        disableDate(item) {
            if (
                new Date(item) - new Date("2019-8-10") >= 0 &&
                new Date("2019-8-20") - new Date(item) >= 0
            ) {
                return true;
            }
            return false;
        },
        handleChange(value) {
            this.addressCode = []
            this.addressCode.push(...value)

        },
        handleClose(done) {
            done();
        },
        selected(e) {
            this.selectAddress = e.province.value + "/" + e.city.value + "/" + e.area.value
            this.addressCode = []
            this.addressCode = [e.province.code, e.city.code, e.area.code]
            this.selectedOptions = []
            this.selectedOptions = [e.province.code, e.city.code, e.area.code]
            this.drawer = false
        },
        // 校验
        checkRules(type) {
            if (this.isNull(this.modlesId)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择设备型号' })
                return
            }
            if (this.isNull(this.serialNum)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '序列号不能为空' })
                return
            }
            // if (this.isNull(this.orderNum)) {
            //     this.$EventBus.$emit("toast", { type: 'error', message: '订单号不能为空' })
            //     return
            // }
            // if (this.isNull(this.buysId)) {
            //     this.$EventBus.$emit("toast", { type: 'error', message: '请选择购买渠道' })
            //     return
            // }
            this.deviceOperation(type)
        },
        // 绑定设备 | 解绑设备 的操作
        deviceOperation(type) {
            let data = {
                "orderNum": this.orderNum,
                "serialNum": this.serialNum,
                "modelId": this.modlesId,

            }
            if (this.buysId) {
                let index = this.buys.findIndex(item => {
                    return this.buysId === item.value
                })
                data.theWayOfBuying = this.buys[index].label
            }
            this.$request({
                method: "post",
                url: type == 'bind' ? this.$requestPath.bindDevice : this.$requestPath.unbindDevice,
                data
            }).then(res => {

                if (type == 'bind') {
                    console.log('绑定设备', res)
                } else {
                    console.log("解绑设备", res)
                }
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: type == 'bind' ? "设备绑定成功" : '设备解绑成功' })
                    this.orderNum = ""
                    this.serialNum = ""
                    this.$store.dispatch("aquireEquipment", this.$EventBus)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("绑定设备报错", error)
            })
        },
        // 设备解绑
        unbindOperation(item) {
            this.$request({
                method: "post",
                url: this.$requestPath.unbindDevice,
                data: {
                    equipmentId: item.equipmentId
                }
            }).then(res => {
                console.log("解绑设备", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: '设备解绑成功' })
                    // this.orderNum = ""
                    // this.serialNum = ""
                    this.$store.dispatch("aquireEquipment", this.$EventBus)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("绑定设备报错", error)
            })
        },
        // 维修校验
        maintenanceCheckRules() {
            if (this.isNull(this.code)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '序列号不能为空' })
                return
            }
            if (this.isNull(this.policy)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '保单信息不能为空' })
                return
            }
            if (this.isNull(this.maintainTime)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择维修时间' })
                return
            }
            if (this.addressCode.length == 0) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择地址' })
                return
            }
            if (this.isNull(this.address)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请填写详细地址' })
                return
            }
            if (this.isNull(this.contactName)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请填写详细地址' })
                return
            }
            if (this.isNull(this.phone)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请填写详细地址' })
                return
            }
            this.maintenanceSubmit()
        },
        // 维修提交
        maintenanceSubmit() {
            if (typeof this.maintainTime == "object") {
                this.maintainTime = this.transformDate(this.maintainTime, 'simple', '-')
            }
            this.$request({
                method: "post",
                url: this.$requestPath.repair,
                data: {
                    modelId: this.modlesId2,// 设备型号
                    code: this.code,// 序列号
                    policy: this.policy,// 保修单信息
                    maintainTime: this.maintainTime,//维修时间
                    cityId: this.addressCode.join(","),//地址
                    address: this.address,// 详细地址
                    contactName: this.contactName,// 联系人姓名
                    phone: this.phone,// 联系人手机号
                }
            }).then(res => {
                console.log("设备维修", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: '设备维修信息提交成功' })
                    this.modelId = ''
                    this.code = ''
                    this.policy = ''
                    this.maintainTime = ''
                    this.addressCode = ''
                    this.address = ''
                    this.contactName = ''
                    this.phone = ''
                    this.selectedOptions = []
                    this.selectAddress = ''
                    this.findRepairList()
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("设备维修报错", error)
            })
        },
        // 获取设备维修信息
        findRepairList() {
            this.$request({
                method: "get",
                url: this.$requestPath.findRepairList,
                data: {}
            }).then(res => {
                if (res.code == 0) {
                    console.log("设备维修列表", res)
                    res.data.forEach(item => {
                        let arr = item.maintainTime.split(" ")
                        item.date = arr[0]
                    })
                    this.maintainList = []
                    this.maintainList.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("获取设备维修列表报错", error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.equipment_box {
    margin: 0 var(--margin-base);
}

.table_title {
    background: #e8e8e8;
}

.li_item {
    width: 33.33%;
}

.ma_li_item {
    width: 25%;
}

@media screen and (max-width: 640px) {
    .form_input {
        width: 15.625rem;
    }

    .btn {
        width: 6.25rem;
    }

    .table {
        margin-top: 0.625rem;

        .table_title {
            height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0px 0.625rem;
        }

        ul {
            li {
                padding: 0.375rem 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }

            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}

@media screen and (min-width: 640px) {
    .form_input {
        width: 18.75rem;
    }

    .btn {
        width: 12.5rem;
    }

    .table {
        margin-top: 0.625rem;

        .table_title {
            height: 2.5rem;
            padding: 0px 0.625rem;
        }

        ul {
            li {
                padding: 1.25rem 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }

            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
</style>
<style>
.btn_item label {
    opacity: 0;
}

.equipment .el-form-item__content {
    margin-left: 10px !important;
    width: calc(100% - 90px);
}
</style>